import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Report = () => import('@/views/Report')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Qna = () => import('@/views/pages/QnA')
const VerifyAccount = () => import('@/views/pages/VerifyAccount')
const Logout = () => import('@/views/pages/Logout')
const Register = () => import('@/views/pages/Register')
const ForgotPassword = () => import('@/views/pages/ForgotPassword')
const ResetPassword = () => import('@/views/pages/ResetPassword')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

//Offer
const ListOffer = () => import('@/views/offer/ListOffer')
const NewOffer = () => import('@/views/offer/NewOffer')
const NewTs = () => import('@/views/ts/NewTrafficSource')
const TsList = () => import('@/views/ts/ListTrafficSource')
const NewCampaign = () => import('@/views/campaign/NewCampaign')
const ClickFinder = () => import('@/views/campaign/ClickFinderPage')
const Iwitness = () => import('@/views/campaign/Iwitness')
const HitMap = () => import('@/views/campaign/HitMap')
const VideoList = () => import('@/views/campaign/VideoList')
const ListCampaign = () => import('@/views/campaign/ListCampaign')
const GenerateSDK = () => import('@/views/campaign/GenerateSDK')
const AccountSetting = () => import('@/views/settings/AccountSetting')
const SDKDocument = () => import('@/views/document/SDKDocument')
const CampaignListDoc = () => import('@/views/document/CampaignList')
const CampaignRemove = () => import('@/views/document/CampaignRemove')
const ClickFinderInfo = () => import('@/views/document/ClickFinderInfo')
const CreateCampaign = () => import('@/views/document/CreateCampaign')
const CreateOffer = () => import('@/views/document/CreateOffer')
const EditOffer = () => import('@/views/document/OfferEdit')
const CreateTrafficSource = () => import('@/views/document/CreateTraficSource')
const DeleteTrafficSource = () => import('@/views/document/DeleteTraficSource')
const EditCampaign = () => import('@/views/document/EditCampaign')
const EditTraficSource = () => import('@/views/document/EditTraficSource')
const GetCampaignInfo = () => import('@/views/document/GetCampaignInfo')
const GetOfferInfo = () => import('@/views/document/GetOfferInfo')
const CampaignList = () => import('@/views/document/ListCampaign')
const OfferList = () => import('@/views/document/ListOffer')
const ListTrafficSource = () => import('@/views/document/ListTrafficSource')
const OfferDelete = () => import('@/views/document/OfferDelete')
const ReportSummary = () => import('@/views/document/ReportSummary')
const ReportDoc = () => import('@/views/document/Report')
const CustomizePostbackDoc = () => import('@/views/document/CustomizePostback')
const TrafficSourceInfo = () => import('@/views/document/TrafficSourceInfo')
const PlanInfo = () => import('@/views/settings/PlanInfo')
const JsSetting = () => import('@/views/settings/JSSetting')
const ApkSetting = () => import('@/views/settings/APKSecurity')
const DomainSetting = () => import('@/views/settings/DomainSecurity')
const CustomDomain = () => import('@/views/settings/CustomDomain')
const IPSetting = () => import('@/views/settings/IPWhitelist')
const PostbackSecurity = () => import('@/views/settings/PostbackSecurity')
const Developer = () => import('@/views/settings/Developer')
const MasterSetting = () => import('@/views/settings/MasterSecurity')
const PostbackLogs = () => import('@/views/settings/PostbackLogs')
const ActivityLogs = () => import('@/views/settings/ActivityLogs')
const Userlist = () => import('@/views/manager/UserList')
const ManagerDash = () => import('@/views/manager/ManagerDash')
const ChecksReport = () => import('@/components/ChecksReport')
const CreatePlan = () => import('@/views/manager/CreatePlan')
const PlanAllocate = () => import('@/views/manager/PlanAllocate')
const ErrorLogs = () => import('@/views/manager/ErrorLogs')
const ManagerOfferlist = () => import('@/views/manager/OfferList')
const ManagerTslist = () => import('@/views/manager/TrafficSourceList')
const ManagerCampaignlist = () => import('@/views/manager/CampaignList')
const WnpSearchPayload = () => import('@/views/manager/WnpSearchPayload')
const Roles = () => import('@/views/manager/Roles')
const SmartSearch = () => import('@/views/campaign/SmartSearch')
const SubUser = () => import('@/views/pages/SubUser')
const SuccessPage = () => import('@/views/pages/SuccessPage')
const ModeratorUserlist = () => import('@/views/moderator/UserList')
Vue.use(Router)

// Redirect HTTP requests to HTTPS
if (window.location.protocol === 'http:') {
  console.log('redirect to https')
  window.location.href = 'https://' + window.location.host + window.location.pathname
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode 'history'
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'MainDashboard',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'report',
          name: 'Report',
          component: Report
        },
        {
          path: '/smartsearch',
          name: 'Smart Search',
          component: SmartSearch
        },
        {
          path:'/subuser',
          name:'Sub User',
          component: SubUser
        },
        {
          path: '/campaign/newcampaign',
          name: 'New Campaign',
          component: NewCampaign
        },
        {
          path: '/campaign/campaignlist',
          name: 'Campaign List',
          component: ListCampaign
        },
        {
          path: '/campaign/generatesdk',
          name: 'Generate SDK',
          component: GenerateSDK
        },
        {
          path: '/campaign/clickfinder',
          name: 'View Logs',
          component: ClickFinder
        },
        {
          path: '/campaign/iwitness',
          name: 'Inspector',
          component: Iwitness
        },
        {
          path: '/campaign/hitmap',
          name: 'HeatMap',
          component: HitMap
        },
        {
          path: '/campaign/videolist',
          name: 'VideoList',
          component: VideoList
        },
        {
          path: '/ts/newts',
          name: 'New TrafficSource',
          component: NewTs
        },
        {
          path: '/ts/tslist',
          name: 'Traffic List',
          component: TsList
        },
        {
          path: '/offer/newoffer',
          name: 'New Offer',
          component: NewOffer
        },
        {
          path: '/offer/offerlist',
          name: 'List Offer',
          component: ListOffer
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
      //   {
      //     path: 'offer',
      //     redirect: '/offer/offerlist',
      //     name: 'Offer',
      //     component: {
      //       render (c) { return c('router-view') }
      //     },
      //     children: [
      //   ]
      // },
    //   {
    //     path: 'ts',
    //     redirect: '/ts/tslist',
    //     name: 'TrafficSource',
    //     component: {
    //       render (c) { return c('router-view') }
    //     },
    //     children: [
        
    //   ]
    // },
    // {
    //   path: 'campaign',
    //   redirect: '/campaign/campaignlist',
    //   name: 'Campaign',
    //   component: {
    //     render (c) { return c('router-view') }
    //   },
    //   children: [
      
    // ]
    // },
    {
      path: 'setting',
      redirect: '/setting/accountsetting',
      name: 'Setting',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
      {
        path: '/setting/accountsetting',
        name: 'Account Setting',
        component: AccountSetting
      },
      {
        path: '/setting/planinfo',
        name: 'Plan Info',
        component: PlanInfo
      },
      {
        path: '/setting/mastersetting',
        name: 'Master Security',
        component: MasterSetting
      },
      {
        path: '/setting/postbacklogs',
        name: 'Postback Logs',
        component: PostbackLogs
      },
      {
        path: '/setting/activitylogs',
        name: 'Activity Logs',
        component: ActivityLogs
      },
      {
        path: '/setting/jssetting',
        name: 'Js Setting',
        component: JsSetting
      },
      {
        path: '/setting/apksetting',
        name: 'Apk Security',
        component: ApkSetting
      },
      {
        path: '/setting/domain',
        name: 'Domain Security',
        component: DomainSetting
      },
      {
        path: '/setting/customdomain',
        name: 'Custom Domain',
        component: CustomDomain
      },
      {
        path: '/setting/ip',
        name: 'IP Whitelist',
        component: IPSetting
      },
      {
        path: '/setting/postbacksecurity',
        name: 'Postback Security',
        component: PostbackSecurity
      },
      {
        path: '/setting/developer',
        name: 'Developer',
        component: Developer
      },
      
    ]
  },
  {
    path: 'document',
    redirect: '/document/sdkdocument',
    name: 'Document',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/document/sdkdocument',
        name: 'SDK Document',
        component: SDKDocument
      },
      {
        path: '/document/campaignlist',
        name: 'List Campaign',
        component: CampaignListDoc
      },
      {
        path: '/document/campaignremove',
        name: 'Delete Campaign',
        component: CampaignRemove
      },
      {
        path: '/document/clickfinderinfo',
        name: 'View Logs Info',
        component: ClickFinderInfo
      },
      {
        path: '/document/createcampaign',
        name: 'Create Campaign',
        component: CreateCampaign
      },
      {
        path: '/document/createoffer',
        name : 'Create Offer',
        component: CreateOffer
      },
      {
        path: '/document/editOffer',
        name : 'Edit Offer',
        component: EditOffer
      },
      {
        path: '/document/createtrafficsource',
        name: 'Create Traffic Source',
        component: CreateTrafficSource
      },
      {
        path: '/document/deletetrafficsource',
        name : 'Delete Traffic Source',
        component: DeleteTrafficSource
      },
      {
        path: '/document/editcampaign',
        name : 'Edit Campaign',
        component: EditCampaign
      },
      {
        path: '/document/edittraficsource',
        name : 'Edit Trafic Source',
        component: EditTraficSource
      },
      {
        path: '/document/getcampaigninfo',
        name : 'Get Campaign Info',
        component: GetCampaignInfo
      },
      {
        path: '/document/getofferinfo',
        name : 'Get Offer Info',
        component: GetOfferInfo
      },
      {
        path: '/document/trafficsourceinfo',
        name : 'Traffic Source Info',
        component: TrafficSourceInfo
      },
      {
        path: '/document/campaignlist',
        name : 'Doc Campaign List',
        component: CampaignList
      },
      {
        path: '/document/offerlist',
        name : 'Offer List',
        component: OfferList
      },
      {
        path: '/document/listtrafficsource',
        name: 'List Traffic Source',
        component: ListTrafficSource
      },
      {
        path: '/document/offerdelete',
        name : 'Offer Delete',
        component: OfferDelete
      },
      {
        path: '/document/reportsummary',
        name : 'Report Summary',
        component: ReportSummary
      },
      {
        path:'/document/reportdoc',
        name : 'Doc Report',
        component: ReportDoc
      },
      {
        path: '/document/customizepostback',
        name: 'Custom Postback',
        component: CustomizePostbackDoc
      }
    ]
  },
  {
    path: 'manager',
    redirect: '/manager/userlist',
    name: 'Manager',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
    {
      path: '/manager/userlist',
      name: 'User list',
      component: Userlist
    },
    {
      path: '/manager/dash',
      name: 'ManagerDash',
      component: ManagerDash
    },
    {
      path: '/manager/checksreprot',
      name: 'Checks Report',
      component: ChecksReport
    },
    {
      path: '/manager/createplan',
      name: 'CreatePlan',
      component: CreatePlan
    },
    {
      path: '/manager/planallocate',
      name: 'PlanAllocate',
      component: PlanAllocate
    },
    {
      path: '/manager/errorlog',
      name: 'Errorlogs',
      component: ErrorLogs
    },
    {
      path: '/manager/offerlist',
      name: 'ManagerOfferlist',
      component: ManagerOfferlist
    },
    {
      path: '/manager/tslist',
      name: 'ManagerTslist',
      component: ManagerTslist
    },
    {
      path: '/manager/campaignlist',
      name: 'ManagerCampaignlist',
      component: ManagerCampaignlist
    },
    {
      path: '/manager/wnpsearchpayload',
      name: 'WnpSearchPayload',
      component: WnpSearchPayload
    },
    {
      path: '/manager/roles',
      name: 'Roles Info',
      component: Roles
    },
    {
      path: '/manager/login',
      name: 'ManagerLogin',
      component: Login
    },
  ]
},
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: 'moderator',
      redirect: '/moderator/userlist',
      name: 'Moderator',
      component: TheContainer,
      children: [
      {
        path: '/moderator/userlist',
        name: 'Moderator User list',
        component: ModeratorUserlist
      },
      {
        path: '/moderator/checksreprot',
        name: 'Checks Report',
        component: ChecksReport
      },
    ]},
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'verifyaccount',
          name: 'VerifyAccount',
          component: VerifyAccount
        },
        {
          path: 'qna',
          name: 'QnA',
          component: Qna
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'forgotpassword',
          name: 'ForgotPassword',
          component: ForgotPassword
        },
        {
          path:'reset',
          name:'ResetPassword',
          component:ResetPassword
        },
        {
          path:'notify',
          name:'Success Page',
          component: SuccessPage
        },
      ]
    }
  ]
}

